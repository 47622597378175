
import { Vue, Component } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import app from '@/store/modules/app'
import { AuthPayload } from '@/models/dto'
import { RawLocation } from 'vue-router'
import AlertBox from '@/components/AlertBox.vue'

@Component({
  components: { AlertBox },
  metaInfo() {
    return {
      title: app.title,
      titleTemplate: `${this.$t('login.TITLE')} - %s`,
    }
  },
})
export default class Login extends Vue {
  valid = false
  errorMessage = false
  isSubmitting = false
  passwordHidden = true
  email = ''
  password = ''
  emailRules = [
    (v) => !!v || this.$t('login.EMAIL_REQUIRED'),
    (v) => /.+@.+/.test(v) || this.$t('login.EMAIL_MUST_BE_VALID'),
  ]
  passwordRules = [(v) => !!v || this.$t('login.PASSWORD_IS_REQUIRED')]

  mounted(): void {
    if (auth.isTokenSet) {
      this.redirect()
    }
  }

  async submit(): Promise<void> {
    if (this.isSubmitting) {
      return
    }
    const form = this.$refs.form as any
    const formIsValid = await form.validate()
    if (formIsValid) {
      this.isSubmitting = true
      const payload: AuthPayload = {
        email: this.email,
        password: this.password,
      }
      try {
        await auth.login(payload)
        await Promise.all([
          auth.refreshUser(),
          auth.refreshRolesAndPermissions(),
          auth.refreshCustomerAccount(),
          auth.refreshCustomer(),
        ])
      } catch (error) {
        this.isSubmitting = false
        this.errorMessage = true
        return
      }
      form.reset()
      this.isSubmitting = false
      this.trackLogin()
      this.redirect()
    }
  }

  trackLogin(): void {
    this.$ga4Event('login', undefined)
  }

  redirect(): void {
    const to = (this.$route.query.redirectFrom || {
      name: 'quote-index',
    }) as RawLocation
    this.$router.push(to)
  }
}
